import './App.css';
import { Box, createTheme, Grid, Icon, Link, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, styled, Typography } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import EmailIcon from '@mui/icons-material/Email';

const services = [
  {
    title: 'Advanced Rendering',
    text: 'Unleash the power of WebGL and the GPU for the most advanced rendering effects possible.',
    icon: 'auto_awesome',
  },
  {
    title: 'Data Visualization',
    text: 'Interactive display of large 2D and 3D datasets.',
    icon: 'auto_graph',
  },
  {
    title: 'Performance Optimization',
    text: 'Reduce latency and resource usage to improve customer satisfaction and cut costs.',
    icon: 'access_time',
  },
  {
    title: 'Media Processing',
    text: 'Bring image, video and audio processing to your web applications.',
    icon: 'slow_motion_video',
  },
  {
    title: 'Full-Stack Approach',
    text: 'Solutions that employ the right mix of realtime and offline computation.',
    icon: 'web_asset',
  },
  {
    title: 'Enterprise Experience',
    text: 'Build for maximum scalability, robustness and security.',
    icon: 'shield',
  },
];

const approachSteps = [
  {
    title: 'Architecture & Design',
    text: `Given your product requirements, we'll help you make the best technology
           decisions to realize your vision, from choosing third-party libraries to
           writing technical design documents.`,
    icon: 'article',
  },
  {
    title: 'Planning',
    text: `We can break down large technical projects into manageable pieces and
           scheduled deliverables, helping you to maximize your engineering
           resources.`,
    icon: 'event_available',
  },
  {
    title: 'Development',
    text: `From writing code and tests to reviewing code, we can work with your
           engineers throughout the development process to keep code standards
           high and the project on track.`,
    icon: 'build',
  },
];

const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h3',
          h3: 'h4',
          h4: 'h5',
          h5: 'h6',
          subtitle1: 'h3',
          subtitle2: 'h4'
        }
      }
    }
  },
  typography: {
    h1: {
      fontFamily: 'Anton',
      fontSize: 74
    },
    h2: {
      fontFamily: 'Anton',
      fontSize: 37
    },
    h3: {
      fontFamily: 'Anton',
      fontSize: 24.5
    },
    h4: {
      fontFamily: 'Anton',
      fontSize: 18.5,
      paddingBottom: '1em'
    },
    h5: {
      fontFamily: 'Anton',
      fontSize: 16.25
    },
    h6: {
      fontFamily: 'Anton',
      fontSize: 14
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: 37,
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: 24.5,
    },
    body1: {
      fontSize: 14
    },
    body2: {
      fontSize: 14
    }
  }
});

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: 'center',
}));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Paper
          component={Stack}
          direction="column"
          display="flex"
          sx={{
            width: '100%',
            height: 480,
            color: '#fff',
            backgroundColor: '#555',
            backgroundImage: 'url(images/finger-pointing-at-laptop.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundBlendMode: 'multiply',
          }}
        >
          <Box textAlign="left" flex="0 0 min-content" paddingLeft={8} paddingTop={4}>
            <img src="images/logo-text-128.png" height={64} />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" flex="1 1 auto" paddingLeft={8}>
            <Typography variant="h1" textAlign="left" maxWidth={640}>
              You Dream It. We Build It.
            </Typography>
            <Typography variant="subtitle2" textAlign="left" maxWidth={640}>
              Full-stack solutions for immersive web applications, data visualization and media processing
            </Typography>
          </Box>
        </Paper>
        <Box
          sx={{
            flexGrow: 1,
            padding: theme.spacing(4),
            backgroundColor: '#044469',
            color: '#fff',
          }}
        >
          <Typography variant="h2">
            Expertise
          </Typography>
          <Grid container columns={{ xs: 4, md: 12 }}>
            {
              services.map(({ title, text, icon }) => (
                <Grid item xs={4}>
                  <Item>
                    <Icon>{icon}</Icon>
                    <Typography variant="h4">
                      {title}
                    </Typography>
                    <Typography variant="body1">
                      {text}
                    </Typography>
                  </Item>
                </Grid>
              ))
            }
          </Grid>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            padding: theme.spacing(4),
            color: '#fff',
            backgroundColor: '#4792be'
          }}
        >
          <Typography variant="h2">
            Services
          </Typography>
          <List sx={{ maxWidth: 600, margin: 'auto' }}>
            {
              approachSteps.map(({ title, text, icon }, index) => {
                const even = true;//index % 2 === 0 ? true : false;
                const justify = even ? 'left' : 'right';
                const justifyFlex = even ? 'flex-start' : 'flex-end';

                const iconEl = (
                  <ListItemIcon sx={{ justifyContent: justify }}>
                    <Icon>{icon}</Icon>
                  </ListItemIcon>
                );

                const textEl = (
                  <ListItemText sx={{ display: 'flex', justifyContent: justifyFlex }}>
                    <Typography variant="h4" align={justify}>
                      {title}
                    </Typography>
                    <Typography variant="body1" align='justify'>
                      {text}
                    </Typography>
                  </ListItemText>
                );

                const elements = even ? [iconEl, textEl] : [textEl, iconEl];

                return (
                  <ListItem sx={{ padding: 2 }}>
                    {elements}
                  </ListItem>
                )
              })
            }
          </List>
        </Box>
        <Paper>
          <Grid container columns={{ xs: 4, md: 8 }}>
            <Grid item xs={4}>
              <img src="images/laptops-on-table.jpg" width="100%" height="100%" style={{ objectFit: 'cover' }} />
            </Grid>
            <Grid item xs={4}>
              <Box textAlign="left" padding={6}>
                <Typography variant="h2">
                  About Us
                </Typography>
                <Typography>
                  <p>We're a small but mighty shop that can augment your existing engineering teams, bringing years of industry experience in developing immersive and performant web applications.</p>
                  <p>From high-performance client-side rendering with WebGL to processing audio and video in the backend, we can help make your graphical and media-heavy apps a reality.</p>
                  <p>Let's talk!</p>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          component={Stack}
          direction="column"
          justifyContent="center"
          sx={{
            width: '100%',
            height: 260,
            backgroundColor: '#444',
            color: '#fff'
          }}
        >
          <Box>
            <EmailIcon />
            <Typography variant="h4" component="h3">
              <Link
                href="mailto:contact@bitmapconsulting.com"
                underline="none"
                color="inherit"
              >
                Get in touch
              </Link>
            </Typography>
          </Box>
        </Paper>
      </div>
    </ThemeProvider>
  );
}

export default App;
